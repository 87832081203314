import { styled } from "@mui/system";

export const Player = styled("audio")({
  "&::-webkit-media-controls-panel": {
    backgroundColor: "powderblue",
    padding: 0,
  },
  "&::-webkit-media-controls-enclosure": {
    borderRadius: 0,
  },
});

export const AboutTitle = styled("h2")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "5rem",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "3rem",
  },
}));
