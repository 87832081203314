import React from "react";
import { Container } from "@mui/material";
import { AboutTitle } from "./Gallery.styles";
import PhotoAlbum from "react-photo-album";
import { photos } from "./photos";

export default function Gallery() {
  return (
    <div>
      <AboutTitle id="galerija">GALERIJA</AboutTitle>
      <Container>
        <PhotoAlbum layout="rows" photos={photos} />;
      </Container>
    </div>
  );
}
