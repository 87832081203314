import LogoLanding from "./components/LogoLanding";
import "./App.css";
import About from "./components/About";
import Gallery from "./components/Gallery";
import Samples from "./components/Samples";
import Videos from "./components/Videos";
import Equipment from "./components/Equipment";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { Container } from "@mui/material";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div>
      <div className="bg"></div>
      <div>
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Navbar />
        </Container>
        <LogoLanding />
        <About />
        <Samples />
        <Videos />
        <Equipment />
        <Gallery />
        <Contact />
        <Footer />
        <CookieConsent style={{ background: "#2B373B" }} expires={150}>
          This website uses cookies to enhance user experience.
        </CookieConsent>
      </div>
    </div>
  );
}

export default App;
