import { styled } from "@mui/system";

export const AboutTitle = styled("h2")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "5rem",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "3rem",
  },
}));

export const MailLink = styled("a")(({ theme }) => ({
  color: "white",
  textDecoration: "none",
}));

export const AboutImageSubtitle = styled("p")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "1rem",
  marginTop: "1rem",
  paddingBottom: "1rem",
  fontFamily: "Open Sans, sans-serif",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    textAlign: "center",
  },
}));
