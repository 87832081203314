import pic1 from "../assets/pic1.jpg";
import pic2 from "../assets/pic2.jpg";
import pic3 from "../assets/pic3.jpg";
import pic4 from "../assets/pic4.jpg";
import pic5 from "../assets/pic5.jpg";
import pic6 from "../assets/pic6.jpg";
import pic7 from "../assets/pic7.jpg";
import pic8 from "../assets/pic8.jpg";
import pic9 from "../assets/pic9.jpg";
import pic10 from "../assets/pic10.jpg";
import pic11 from "../assets/pic11.jpg";
import pic12 from "../assets/pic12.jpg";
import pic13 from "../assets/pic13.jpg";
import pic14 from "../assets/pic14.jpg";
import pic15 from "../assets/pic15.jpg";

export const photos = [
  {
    src: pic1,
    width: 600,
    height: 400,
  },
  {
    src: pic2,
    width: 600,
    height: 400,
  },
  {
    src: pic3,
    width: 600,
    height: 400,
  },
  {
    src: pic4,
    width: 600,
    height: 400,
  },
  {
    src: pic5,
    width: 600,
    height: 400,
  },
  {
    src: pic6,
    width: 400,
    height: 600,
  },
  {
    src: pic7,
    width: 400,
    height: 600,
  },
  {
    src: pic8,
    width: 600,
    height: 400,
  },
  {
    src: pic9,
    width: 600,
    height: 400,
  },
  {
    src: pic10,
    width: 600,
    height: 400,
  },
  {
    src: pic11,
    width: 400,
    height: 600,
  },
  {
    src: pic12,
    width: 600,
    height: 400,
  },
  {
    src: pic13,
    width: 600,
    height: 400,
  },
  {
    src: pic14,
    width: 600,
    height: 400,
  },
  {
    src: pic15,
    width: 400,
    height: 600,
  },
];
