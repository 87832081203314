import React from "react";
import { AboutTitle } from "./Equipment.styles";
import { Container, List, ListItem } from "@mui/material";

export default function Equipment() {
  const equipmentList = [
    "Presonus StudioLive III 24.4.2",
    "Behringer Truth B2031A",
    "Behringer Truth B2092A sub",
    "Cubase 12 Pro",
    "Virtual Microphone System VMS ML-1 SlateDigital",
    "AKG Perception 420",
    "Audix D6",
    "Shure Beta 91A",
    "Shure SM 57 2x",
    "Shure Beta 57",
    "Shure SM 58",
    "Sennheiser E604 3x",
    "Rode NT5 2x",
    "Pearl Decade Maple Fusion S. Black 22, 10, 12, 14, 14 x 5.5",
    "Alesis DM10 Studio Kit",
  ];

  return (
    <Container maxWidth="sm">
      <AboutTitle id="oprema">OPREMA</AboutTitle>
      <List sx={{ background: "rgba(0,0,0,0.9)" }}>
        {equipmentList.map((item) => {
          return (
            <ListItem
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
                fontSize: "1rem",
                textAlign: "center",
              }}
              key={item}
            >
              {item}
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
}
