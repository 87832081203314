import { styled } from "@mui/system";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "0 auto",
  marginTop: "5rem",
});

export const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const ImageWithText = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: "5rem",
  marginRight: "5rem",
  marginBottom: "0rem",
  marginTop: "2rem",
}));

export const AboutTitle = styled("h2")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "5rem",
  fontFamily: "Open Sans, sans-serif",
  backgroundColor: "black",
  [theme.breakpoints.down("md")]: {
    fontSize: "3rem",
  },
}));

export const AboutImageSubtitle = styled("p")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "1rem",
  marginTop: "1rem",
  fontFamily: "Open Sans, sans-serif",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    textAlign: "center",
  },
}));

export const ReviewText = styled("p")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "1.5rem",
  marginTop: "1rem",
  marginBottom: "1rem",
  fontFamily: "Open Sans, sans-serif",
  fontStyle: "italic",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
  },
}));
