import { styled } from "@mui/system";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "2rem",
});

export const Title = styled("h1")({
  color: "white",
  textAlign: "center",
  paddingTop: "5rem",
  fontSize: "5rem",
});

export const Logo = styled("img")(({ theme }) => ({
  margin: "8rem 3rem 3rem 3rem",
  [theme.breakpoints.down("md")]: {
    marginTop: "2rem",
  },
}));
