import React from "react";
import { Container } from "@mui/material";
import {
  AboutTitle,
  ImageContainer,
  ImageWithText,
  AboutImageSubtitle,
  ReviewText,
} from "./About.styles";
import pic9 from "../assets/pic9.jpg";
import pic2 from "../assets/pic2.jpg";
import pic13 from "../assets/pic13.jpg";

export default function About() {
  return (
    <div>
      <AboutTitle id="onama">O NAMA</AboutTitle>
      <Container maxWidth="xl">
        <ImageContainer>
          <ImageWithText>
            <img src={pic9} width="100%" height="auto" alt="studio 1" />
            <AboutImageSubtitle>
              Studio vodi i upravlja Silvien Krošnjak, vlasnik studija. Glazbom
              i produciranjem se bavi 15 godina te ima mnogo iskustva u
              snimanju, miksanju, masteriziranju pjesama. <br /> Glazbeni studio
              Pehlin bavi se snimanjem zvučnog zapisa, produkcijom i obradom
              zvučnih zapisa. Naša posebnost leži u tome što Vama pružamo
              priliku da se umjetnički izrazite, njegujući Vaš stil, karakter i
              osobine u svim žanrovima.
            </AboutImageSubtitle>
            <ReviewText>
              "Excellent cooperation, quality work, professionalism and speed in
              communication. Definitely recommended to anyone looking for a good
              recording studio. We will surely cooperate again!" - Ivana Surjan
              Mitrovic - Mohanji Croatia Team
            </ReviewText>
          </ImageWithText>
          <ImageWithText>
            <img src={pic2} width="100%" height="auto" alt="studio 2" />
            <AboutImageSubtitle>
              S obzirom na raznolikost iskustva, profesionalan i temeljit
              pristup, izvrstan je u prepoznavanju što klijent želi. Važno je
              istaknuti i široko znanje o teoriji zvuka, frekventskom rasponu
              instrumenata i vokala.
            </AboutImageSubtitle>
            <ReviewText>
              "Silvien is an extraordinary person and musician. The studio and
              atmosphere for work are unforgettable. Quality recordings and a
              professional approach are what you will definitely find here. I
              highly recommend it." - Sandi Bratonja - Urban & 4
            </ReviewText>
          </ImageWithText>
          <ImageWithText>
            <img src={pic13} width="100%" height="auto" alt="studio 3" />
            <AboutImageSubtitle>
              U skladu s tim prostor je izvrsno izoliran koristeći svjetske
              standarde zvučne izolacije i apsorpcije zvuka kako bi se dobio
              gotovo linearan odaziv frekvencija prilikom snimanja i
              reprodukcije zvuka. Studio se sastoji od dvije sobe, sobe za
              snimanje i kontrolne sobe. Opremljen vrhunskim mikrofonima i
              najnovijom digitalnom opremom nudi usluge višekanalnog snimanja
              kompletnog benda, zbora i sl. kao i mogućnost pojedinačnog
              snimanja instrumenata i vokala.
            </AboutImageSubtitle>
            <ReviewText>
              "Expert, pleasant, professional. Top!" - Tomislav Maslak -
              PleteRi, Trio Frix
            </ReviewText>
          </ImageWithText>
        </ImageContainer>
      </Container>
    </div>
  );
}
