import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { AboutTitle, MailLink, AboutImageSubtitle } from "./Contact.styles";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Contact() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      maxWidth="sm"
    >
      <AboutTitle id="kontakt">KONTAKT</AboutTitle>
      <AboutImageSubtitle>
        Potencijalne klijente (poslodavci- obveznici kvote za zapošljavanje
        osoba s invaliditetom) želim informirati da potpisivanjem ugovora o
        poslovnoj suradnji sa mnom kao samozaposlenom osobom s invaliditetom,
        mogu ispuniti svoju zamjensku kvotu ( Zakon o profesionalnoj
        rehabilitaciji i zapošljavanju osoba s invaliditetom NN 157/13, 152/14,
        39/18, 32/20, Pravilnik o utvrđivanju kvote za zapošljavanje osoba s
        invaliditetom NN 75/18, 120/18, 37/20, 145/20 ).
      </AboutImageSubtitle>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <BusinessIcon style={{ color: "white", marginRight: "0.5rem" }} />
            <Typography color="white">
              Turkovo 23/3, 51000, Rijeka, Croatia
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <PhoneInTalkIcon
              style={{ color: "white", marginRight: "0.5rem" }}
            />
            <Typography color="white">+385 95 884 4847</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <EmailIcon style={{ color: "white", marginRight: "0.5rem" }} />
            <MailLink href="mailto:krosnjak@gmail.com">
              <Typography color="white">email: krosnjak@gmail.com</Typography>
            </MailLink>
          </Box>
          <Box
            sx={{ marginBottom: "1rem" }}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <a
              target="_blank"
              href="https://www.facebook.com/Glazbeni-Studio-Pehlin-103184937762865/"
            >
              <FacebookIcon
                style={{
                  color: "dodgerblue",
                  marginRight: "0.5rem",
                  fontSize: "3rem",
                }}
              />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/user/Silvienk1/featured"
            >
              <YouTubeIcon
                style={{
                  color: "red",
                  marginRight: "0.5rem",
                  fontSize: "3rem",
                }}
              />
            </a>
          </Box>
        </Box>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11214.0875664227!2d14.3965389!3d45.3584627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4764a7eed26fe909%3A0xd6e452d36ba05087!2sGlazbeni%20studio%20Pehlin!5e0!3m2!1sen!2sie!4v1698851571081!5m2!1sen!2sie"
          width="400"
          height="250"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Container>
  );
}
