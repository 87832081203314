import { styled } from "@mui/system";

export const Iframe = styled("iframe")(({ theme }) => ({
  width: "50%",
  height: "auto",
  minHeight: "215px",
  border: "none",
  margin: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
}));

export const AboutTitle = styled("h2")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "5rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "3rem",
  },
}));
