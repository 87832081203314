import React from "react";

export default function Footer() {
  const styles = {
    width: "100vw",
    marginTop: "1rem",
    backgroundColor: "black",
  };

  return (
    <div style={styles}>
      <h4 style={{ color: "white", textAlign: "center" }}>
        Studio Pehlin © 2023
      </h4>
    </div>
  );
}
