import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logoImg from "../assets/isolated-monochrome-white.svg";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const ids = ["onama", "samples", "videos", "oprema", "galerija", "kontakt"];

  const list = (anchor) => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ background: "black" }}>
        {["O NAMA", "SAMPLES", "VIDEOS", "OPREMA", "GALERIJA", "KONTAKT"].map(
          (text, index) => (
            <ListItem
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
              key={text}
              disablePadding
            >
              <ListItemButton href={`#${ids[index]}`}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  const appbar = (
    <AppBar sx={{ backgroundColor: "black" }} position="fixed">
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Button href="#top">
          <img src={logoImg} width="100px" height="100px" />
        </Button>
        <Button href="#onama" sx={{ color: "white", fontSize: "1.2rem" }}>
          O NAMA
        </Button>
        <Button href="#samples" sx={{ color: "white", fontSize: "1.2rem" }}>
          SAMPLES
        </Button>
        <Button href="#videos" sx={{ color: "white", fontSize: "1.2rem" }}>
          VIDEOS
        </Button>
        <Button href="#oprema" sx={{ color: "white", fontSize: "1.2rem" }}>
          OPREMA
        </Button>
        <Button href="#galerija" sx={{ color: "white", fontSize: "1.2rem" }}>
          GALERIJA
        </Button>
        <Button href="#kontakt" sx={{ color: "white", fontSize: "1.2rem" }}>
          KONTAKT
        </Button>
      </Toolbar>
    </AppBar>
  );

  return isMobile ? (
    <div>
      {["top"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            sx={{
              left: "83%",
              top: -5,
              position: "fixed",
              zIndex: state.top === false ? 2000 : 0,
            }}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon
              sx={{ color: "white", transform: "scale(2)", marginTop: "1rem" }}
            />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  ) : (
    appbar
  );
}
