import React from "react";
import { Player, AboutTitle } from "./Samples.styles";
import { Container, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { samplesObj1 } from "./sampleList";

export default function Samples() {
  const mapTracks = samplesObj1.map((sample, index) => {
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: "0.5rem",
          background: "rgba(0,0,0,0.8)",
        }}
        key={index}
      >
        <CardContent>
          <Typography
            sx={{ color: "white", textAlign: "center" }}
            component="div"
            variant="h6"
          >
            {sample.subtitle}
          </Typography>
          <Player controls>
            <source src={sample.audioPath} />
          </Player>
        </CardContent>
      </Card>
    );
  });

  return (
    <div>
      <AboutTitle id="samples">SAMPLES</AboutTitle>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        maxWidth="lg"
      >
        {mapTracks}
      </Container>
    </div>
  );
}
