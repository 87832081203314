import React from "react";
import IntroText from "./IntroText";
import { Container, Logo } from "./LogoLanding.styles";
import Button from "@mui/material/Button";
import logo from "../assets/default-monochrome-white.svg";

export default function LogoLanding() {
  return (
    <Container>
      <Logo src={logo} />
      <IntroText />
      <Button
        href="#kontakt"
        size="small"
        variant="contained"
        sx={{
          margin: "1rem auto 0 auto",
          padding: "0.5rem 2rem 0.5rem 2rem",
          border: "1px solid black",
        }}
      >
        Kontaktirajte nas
      </Button>
    </Container>
  );
}
