import React from "react";
import { Iframe, AboutTitle } from "./Videos.styles";
import { Container } from "@mui/material";

export default function Videos() {
  return (
    <div>
      <AboutTitle id="videos">VIDEOS</AboutTitle>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Iframe
          src="https://www.youtube.com/embed/9K2NaYO4vvo?si=fSgJlxiWSx_D3auP"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></Iframe>
        <Iframe
          src="https://www.youtube.com/embed/Hlhk8V_w2so?si=I0WpJjGYvXBLIAtz"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></Iframe>
        <Iframe
          src="https://www.youtube.com/embed/IOxNSTQt24o?si=-K2ucxv6kU6b20oS"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></Iframe>
      </Container>
    </div>
  );
}
