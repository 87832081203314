import React from "react";
import { Container, Text } from "./IntroText.styles";

export default function IntroText() {
  return (
    <Container>
      <Text>MALI STUDIO VELIKIH MOGUĆNOSTI</Text>
    </Container>
  );
}
