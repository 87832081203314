import { styled } from "@mui/system";

export const AboutTitle = styled("h2")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontSize: "5rem",
  marginBottom: "1rem",
  marginTop: "1rem",
  backgroundColor: "black",
  [theme.breakpoints.down("md")]: {
    fontSize: "3rem",
  },
}));
