import { styled } from "@mui/system";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const Text = styled("h2")(({ theme }) => ({
  color: "white",
  textAlign: "center",
  marginBottom: "2rem",
  fontFamily: "Josefin Sans, sans serif",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
  },
}));
