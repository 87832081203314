import metalRaw from "../samples/METAL_RAW.mp3";
import metalMix from "../samples/METAL_MIX.mp3";
import metalMaster from "../samples/METAL_MASTER.mp3";
import polkaRaw from "../samples/POLKA_RAW.mp3";
import polkaMix from "../samples/POLKA_MIX.mp3";
import polkaMaster from "../samples/POLKA_MASTER.mp3";
import popRaw from "../samples/POP_RAW.mp3";
import popMix from "../samples/POP_MIX.mp3";
import popMaster from "../samples/POP_MASTER.mp3";
import punkRaw from "../samples/PUNK_RAW.mp3";
import punkMix from "../samples/PUNK_MIX.mp3";
import punkMaster from "../samples/PUNK_MASTER.mp3";

export const samplesObj = [
  {
    mainTitle: "Metal",
    sampleDetails: [
      {
        subtitle: "Raw",
        audioPath: metalRaw,
      },
      {
        subtitle: "Mix",
        audioPath: metalMix,
      },
      {
        subtitle: "Master",
        audioPath: metalMaster,
      },
    ],
  },
  {
    mainTitle: "Polka",
    sampleDetails: [
      {
        subtitle: "Raw",
        audioPath: polkaRaw,
      },
      {
        subtitle: "Mix",
        audioPath: polkaMix,
      },
      {
        subtitle: "Master",
        audioPath: polkaMaster,
      },
    ],
  },
  {
    mainTitle: "Pop",
    sampleDetails: [
      {
        subtitle: "Raw",
        audioPath: popRaw,
      },
      {
        subtitle: "Mix",
        audioPath: popMix,
      },
      {
        subtitle: "Master",
        audioPath: popMaster,
      },
    ],
  },
  {
    mainTitle: "Punk",
    sampleDetails: [
      {
        subtitle: "Raw",
        audioPath: punkRaw,
      },
      {
        subtitle: "Mix",
        audioPath: punkMix,
      },
      {
        subtitle: "Master",
        audioPath: punkMaster,
      },
    ],
  },
];

export const samplesObj1 = [
  {
    subtitle: "Metal Raw",
    audioPath: metalRaw,
  },
  {
    subtitle: "Metal Mix",
    audioPath: metalMix,
  },
  {
    subtitle: "Metal Master",
    audioPath: metalMaster,
  },

  {
    subtitle: "Polka Raw",
    audioPath: polkaRaw,
  },
  {
    subtitle: "Polka Mix",
    audioPath: polkaMix,
  },
  {
    subtitle: "Polka Master",
    audioPath: polkaMaster,
  },

  {
    subtitle: "Pop Raw",
    audioPath: popRaw,
  },
  {
    subtitle: "Pop Mix",
    audioPath: popMix,
  },
  {
    subtitle: "Pop Master",
    audioPath: popMaster,
  },

  {
    subtitle: "Punk Raw",
    audioPath: punkRaw,
  },
  {
    subtitle: "Punk Mix",
    audioPath: punkMix,
  },
  {
    subtitle: "Punk Master",
    audioPath: punkMaster,
  },
];
